.image {
  /*
    display: block;
    max-width: 100%;
    height: auto;
    max-height: -webkit-fill-available;
    */
    max-width: 100%;
    max-height: calc(100vh - 150px);
    
  }
  
  .loading {
    filter: blur(10px);
    clip-path: inset(0);
  }
  
  .loaded {
    filter: blur(0px);
    transition: filter 0.5s ease-in;
  }