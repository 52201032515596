#gallery-cover{
    color: #00af43;
  
    text-align: center;
    /*margin-top:100px;
    margin-bottom: 10px;*/
  }
   
  .button {
    display: inline-flex;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    vertical-align: middle;
    text-decoration: none;
    background-color: #00af43;
    color: white;
    border: 1px solid #00af43;
    border-radius: 10px;
  }
  
  .button:hover {
      background-color: white;
    color: #00af43;
    border: 1px solid #00af43;
    cursor: pointer;
    
  }