.galleryPhotos{
    vertical-align: top;
    padding-right: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

 .galleryNavigation{
  text-align: left;
 }

