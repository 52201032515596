ul{
    padding: 0px;
    color: #00af43;
    font-size: large;
    list-style:none;

}
li{
  display: inline;
}
.link{
    text-decoration: none;
    cursor: pointer;
    border: 0px;
    background-color: white;
    color: #00af43;
  }
  
.link:hover {
    text-decoration: underline;
    color: #00af43; 
  }
  
.arrow{
    padding-left: 5px;
    padding-right: 5px;
}