.header {
	display: flex;
	justify-content: right;
  padding:5px;
  border: 1px solid #00af43;
  background-color: #00af43;
  color: white;
  height: 70px;
}

.headerLogo{
  display: flex;
  justify-content: left;
  height: 70px;
}

.headerLogo img{
  max-height: 70px;
}

.headerLanguage{
  display: flex;
  justify-content: right;
  padding-right: 10px;
}

.headerLanguage .link{
  border:none;
  background: none;
  padding: 0;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
  
.headerLanguage .activeLink{
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}