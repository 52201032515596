.container{
    padding: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00af43;
}


.imgFadeIn{
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  /***************************************************************************************************/
  .img{
    -webkit-filter: grayscale(0);
    filter: none;
   /* transition: all ease 0.5s;*/
    cursor: pointer;
    width: 100%;
    display: block;
}
  .img:hover  {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    transform: scale(1.02);
  }

  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all 1s;
  }

 /* //hide button for touch screens
  @media (hover: none) and (pointer: coarse) {
    .btnDownload {
        display: none;
    }
}
*/

/*  //hide the button for devices with screen size smaller then 1000px 
@media (max-width: 1000px) {
  .btnDownload {
      display: none;
  }
}
*/
  .sliderWrap .btnClose, .sliderWrap .btnDownload, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 8999;
  }

  .donwloadMenu{
    position: fixed;
    top: 60px;
    right: 80px;
    z-index: 9999;
    color: #fff;
    font-size: small;
  }
  .donwloadMenu button{
    border:0px;
    width: 100%;
    text-align: left;
  }

  .donwloadMenu button:hover{
    background-color: #ccc;
  }

  .btnNext:hover, .btnPrev:hover, .btnClose:hover, .btnDownload:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnDownload {
    top: 40px;
    right: 80px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    
    width: calc(100vh - 40px);
    height: calc(100vh- 40px);
    /*
    display: flex;
    align-items: center;
    justify-content: center;
*/
  }
  
  .fullScreenImage:focus {
    border: 0px;
    outline: none;
  }

  
  .fullScreenImage_old img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
    /*transition: all 20ms ease-in-out 0s;*/
  }

  .fullScreenImage img {
    max-width: 100%;/*calc(100vh - 40px);*/
    max-height: calc(100vh - 40px);
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;
}
