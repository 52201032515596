#root {

  width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  scroll-padding-bottom: 60px;
  overflow-x: hidden;
}

body { padding-top: 70px; }

.mainContainer{
  margin-left: 10px;
  margin-right: 10px;
}

.centered {
  /*margin: 3rem auto;*/
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00af43;
}

.title{
  color: #00af43;
  margin-bottom: 10px;

}

.gridimg{
  -webkit-filter: grayscale(0);
  filter: none;
 /* transition: all ease 0.5s;*/
  cursor: pointer;
  width: 100%;
  display: block;
}
.gridimg:hover  {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  transform: scale(1.02);
}

